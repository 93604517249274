import { COOKIES, CURRENCIES } from '~/constants'
import type { Nullable } from '~/types'

export default function useCurrency() {
  const runtimeConfig = useRuntimeConfig()
  const { isInCa } = useGeolocation()

  const preferredCurrencyCookie = useCookie(COOKIES.currency, {
    domain: runtimeConfig.public.cookieDomain,
  })

  function updatePreferredCurrency(currency: Nullable<CURRENCIES>) {
    preferredCurrencyCookie.value = (currency?.toString() as CURRENCIES) || undefined
  }

  const defaultCurrency = computed(() => isInCa.value ? CURRENCIES.CAD : CURRENCIES.USD)

  const selectedCurrencyCode = computed(() => preferredCurrencyCookie.value || defaultCurrency.value)

  return {
    selectedCurrencyCode,
    preferredCurrency: preferredCurrencyCookie,
    updatePreferredCurrency,
  }
}
